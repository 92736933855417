import React, { useEffect, useState } from 'react';
import { ParametresApi } from 'api/ParametresApi';
import { FormatAdressOneLine } from 'lib/FormatAdress';
import FormatDate from 'lib/FormatDate';
import Button from '../../0components/Button';
import { PDFDownloadLink, Text, Row, View, Table2, CellHeader, Cell } from '../PdfWorker';
import AssuranceLayoutPDF from './AssuranceLayoutPDF';
import { ToEuro } from 'lib/FormatNum';

export default ({ demande }) => {
    const [cout, setCout] = useState();
    const [iban, setIban] = useState();
    const [bic, setBic] = useState();

    useEffect(_ => {
        ParametresApi
            .get("MONTANT_A201")
            .then(res => setCout(parseFloat(res.value)))

        ParametresApi
            .get("ASSNUMCPTIBAN")
            .then(res => setIban(res.value))

        ParametresApi
            .get("ASSNUMCPTBIC")
            .then(res => setBic(res.value))
    }, [])

    if (!cout || !demande || !iban || !bic)
        return <p>chargement</p>;

    return (<PDFDownloadLink document={<MonDocument demande={demande}  cost={cout} iban={iban} bic={bic}/>} fileName={`DemandeAssurance${demande.numeroDemande}.pdf`}>
        {({ loading }) => (loading ? 'Génération du document...' : <Button icon="fa-download">Télécharger</Button>)}
    </PDFDownloadLink>);
}

const myDate = d => d ? FormatDate(d) : "...../...../.....";

const MonDocument = ({ demande, cost, iban, bic }) => {
    return (<AssuranceLayoutPDF demande={demande} titre="Incapacité temporaire" subtitle="Demande de couverture d'assurance: Police n° 45.031.655" roi="ROI ASS 201">
        <Table2>
            <Row>
                <CellHeader style={{ width: "15%" }}>Matricule</CellHeader>
                <CellHeader style={{ width: "20%" }}>Nom</CellHeader>
                <CellHeader style={{ width: "20%" }}>Prénom</CellHeader>
                <CellHeader style={{ width: "30%" }}>Adresse</CellHeader>
                <CellHeader style={{ width: "15%" }}>Naissance</CellHeader>
            </Row>
            {demande?.affilies?.map((e, i) => <Row key={i}>
                <Cell style={{ width: "15%" }}>{e?.matricule ?? " "}</Cell>
                <Cell style={{ width: "20%" }}>{e?.lastName ?? " "}</Cell>
                <Cell style={{ width: "20%" }}>{e?.firstName ?? " "}</Cell>
                <Cell style={{ width: "30%" }}>{FormatAdressOneLine(e?.coord?.adress)}</Cell>
                <Cell style={{ width: "15%" }}>{myDate(e?.birthDate)}</Cell>
            </Row>)}
        </Table2>

        {/*<View>*/}
        {/*    <Text style={{ fontSize: 8, marginBottom: 0, borderWidth: 1, marginTop: 20, padding: 3 }}>{demande?.message}</Text>*/}
        {/*</View>*/}

        <View style={{ borderWidth: 1, padding: 3, marginTop: 20 }}>
            <Text style={{ fontSize: 8 }}>{`Je verse la somme de ${ToEuro(demande?.affilies?.length * cost)} soit ${demande?.affilies?.length} x ${ToEuro(cost)}`}</Text>
            <Text style={{ fontSize: 8 }}>{`Sur le compte ${iban} - ${bic}`}</Text>
            <Text style={{ fontSize: 8 }}>{`"Assurances F.F.B.M.P. asbl - C.A."`}</Text>
        </View>
    </AssuranceLayoutPDF>);
}